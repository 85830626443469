<template>
  <div>
    <b-overlay :show="isFetching">
      <b-row>
        <b-col
          v-for="(coupon, key) in couponSettings"
          :key="key"
        >
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <h5>
                {{ $t('rewards.coupon_settings')}}
              </h5>
              <span class="text-right text-muted mb-2">
                {{ $t('fields.updatedAt')}}:
                {{coupon.updatedAt | datetime}}
              </span>
            </div>
            <CouponForm
              :value="coupon"
              :is-loading="isUpdating"
              @submit="onUpdate"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-card>
      <h5>
        {{$t('rewards.coupon_redeem_history')}}
      </h5>
      <CouponHistory />
    </b-card>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CouponSettings',
  components: {
    CouponForm: () => import('../../../components/forms/coupon-form'),
    CouponHistory: () => import('./coupon-history'),
  },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.user.isFetchingCouponSettings,
      isUpdating: (state) => state.user.isUpdatingCouponSettings,
      couponId: (state) => state.user.couponId,
    }),
    ...mapGetters(['couponSettings']),
  },
  watch: {
    agentId(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchCouponSettings', 'updateCouponSettings']),
    fetchData() {
      if (this.agentId) {
        this.fetchCouponSettings()
      }
    },
    onUpdate(data) {
      this.updateCouponSettings({
        agentId: this.agentId,
        id: data.id,
        data,
      })
    },
  },
}
</script>
